import React, { useState, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import { connect } from "react-redux";
import socketIOClient from "socket.io-client";

// Constant
import URLs from "./utils/urls";

// Dispatcher
import mapDispatchToProps from "./reducers/dispatchers";

// Components
import Header from "./components/SimFlex/Header";
import SimFlex from "./components/SimFlex/SimFlex";
import SimFlexMAN from './components/SimFlexMAN/SimFlex';
import KitsAndObjects from "./components/SimFlex/KitsAndObjects";
import FilesLibrary from "./components/SimFlex/FilesLibrary";

const AppProvider = (props) => {

	const [socket, setSocket] = useState(undefined)
	const [canEdit, setCanEdit] = useState(false)
	const [isAdmin, setIsAdmin] = useState(false)
	const [teamName, setTeamName] = useState("")
	const [languages, setLanguages] = useState([])

	const [boardLayout, setBoardLayout] = useState("")

	useEffect(() => {
		if (!socket) {
			if (localStorage.langId) {
				props.changeLanguage(localStorage.langId);
			}

			const io = socketIOClient(URLs.socketEndpoint, {
				upgrade: true,
				transports: ["websocket", "polling"]
			});
			window.socket = io;
			setSocket(io)
		}
	}, [props, socket])

	useEffect(() => {
		setCanEdit(props.canEdit)
	}, [props.canEdit])

	useEffect(() => {
		setIsAdmin(props.isAdmin)
	}, [props.isAdmin])

	useEffect(() => {
		setTeamName(props.teamName)
	}, [props.teamName])

	useEffect(() => {
		if (languages.length === 0) {
			setLanguages(props.languages)
		}
	}, [props.languages, languages])

	useEffect(() => {
		if (socket) {
			socket.on("connect", data => {
				console.log("Connected To WS")
	
				socket.emit('user-data', {
					user_id: window.playerId,
					team_id: window.teamId,
					session_id: window.sessionId,
					token: window.token
				});
			});
	
	
			socket.on("item-dragging", data => {
				props.updateDragStatus(data.alias, data.type, data.isDragging, data)
			});
			socket.on("capital-editing", data => {
				props.updateCapitalStatus(data.type, data.isDragging, data);
			})
	
	
			socket.on("add-message", data => {
				props.addMessage(data);
			})
	
			socket.on("messages-list", data => {
				props.addMessagesList(data);
			})
	
			socket.on("change-team", data => {
				props.updateTeamName(data.value);
			})
	
			socket.on("change-year", data => {
				props.updateYear(data);
			})
	
			socket.on("restore-form-status", data => {
				console.log(data);
				props.restoreFormStatus(data);
			});
	
			socket.on("form-status", data => {
				console.log(data);
				props.updateFormStatus(data);
			});
	
			socket.on("files-list", data => {
				props.updateFiles(data);
			});
	
			socket.on("add-file", data => {
				if (parseInt(data.teamId) === parseInt(window.teamId) || parseInt(data.teamId) === 0) {
					props.addFile(data);
				}
			});
	
			socket.on("remove-file", data => {
				if (parseInt(data.teamId) === parseInt(window.teamId) || parseInt(data.teamId) === 0) {
					props.removeFile(data._id);
				}
			});
	
			socket.on("add-timer", data => {
				props.updateCounter(data.data);
			})
	
			socket.on("remove-timer", data => {
				props.updateCounter({
					duration: 0,
					createdAt: 0
				});
			})
	
			socket.on('session-layout', data => {
				setBoardLayout(data ? data : 'Simflex')
			})
			socket.on("restore-session", data => {
				props.updateState(data);
				if (data.activeForm !== undefined) {
					console.log(data.activeForm);
					props.updateFormStatus(data.activeForm);
				}
			});
	
			socket.on("update-capital", data => {
				props.updateCapital(data.value);
			})
	
			socket.on("add-token", data => {
				props.addToken(data.type, data.value, data.droppedTokenType);
			})
	
			socket.on("remove-token", data => {
				props.removeToken(data.type, data.value);
			})
	
			socket.on("add-container", data => {
				if (data.fieldType === undefined) {
					if (data.hasCard !== undefined && data.cardColor !== undefined) {
						props.addContainerDirectCost(data.alias, data.id, data.coins, data.hasCard, data.cardValue, data.cardColor, data.playerId, data.playerName)
					}else{
						props.addContainer(data.alias, data.id, data.coins, data.playerId, data.playerName);
					}
				}else{
					if (data.hasCard !== undefined && data.cardColor !== undefined) {
						props.addContainerColorField(data.alias, data.fieldType, data.id, data.coins, data.hasCard, data.cardValue, data.cardColor, data.playerId, data.playerName)
					}else {
						props.addContainerFieldType(data.alias, data.fieldType, data.coins, data.id, data.hasCard, data.cardValue, data.cardColor, data.playerId, data.playerName);
					}
				}
			});
	
			socket.on("remove-container", data => {
				if (data.fieldType === undefined && data.color === undefined) {
					props.removeContainer(data.alias, data.id);
				}else if (data.color !== undefined) {
					props.removeContainerColorField(data.alias, data.color, data.id);
				}else{
					props.removeContainerFieldType(data.alias, data.fieldType, data.id);
				}
			});
	
			socket.on("add-coin", data => {
				console.log(data);
	
				if (data.fieldType === undefined) {
					props.addCoin(data.alias, data.id, data.value, data.playerId, data.playerName);
				}else{
					props.addCoinFieldType(data.alias, data.fieldType, data.value, data.id, data.playerId, data.playerName);
				}
			});
	
			socket.on("remove-coin", data => {
				if (data.fieldType === undefined) {
					props.removeCoin(data.alias, data.id, data.index, data.playerId, data.playerName);
				}else{
					props.removeCoinFieldType(data.alias, data.fieldType, data.index, data.id, data.playerId, data.playerName);
				}
			});
	
			socket.on("add-coins", data => {
				if (data.fieldType === undefined) {
					props.addCoins(data.alias, data.id, data.coins, data.playerId, data.playerName);
				}else{
					props.addCoinsFieldType(data.alias, data.fieldType, data.coins, data.id, data.playerId, data.playerName);
				}
			});
	
			socket.on("update-coins", data => {
				if (data.fieldType === undefined) {
					props.updateCoins(data.alias, data.id, data.coins, data.playerId, data.playerName);
				}else{
					props.updateCoinsFieldType(data.alias, data.fieldType, data.coins, data.id, data.playerId, data.playerName);
				}
			});
	
			socket.on("add-card", data => {
				props.addCard(data.alias, data.optionType, data.id, data.value, data.color);
			});
	
			socket.on("rent-factory", data => {
				props.rentFactory(data.id);
			});
	
			socket.on("purchase-factory", data => {
				props.purchaseFactory(data.id);
			});
	
			socket.on("update-team-table", data => {
				props.updateTeamTable(data);
			})
	
			socket.on('add-pin', data => {
				props.addPin(data.alias, data.title, data.speed, data.id);
			});
	
			socket.on('remove-pin', data => {
				props.removePin(data.alias, data.title, data.speed, data.id);
			});
	
			socket.on('remove-starter-kit', data => {
				props.removeStartKit(data.id);
			});
	
			socket.on('decrease-starter-kit', data => {
				props.decreaseStarterKit(data)
			})
	
			socket.on('increase-starter-kit', data => {
				props.increaseStarterKit(data)
			})
	
			socket.on('add-starter-kit-coin', data => {
				props.increaseStartKitCoin(data.value);
			});
	
			socket.on('remove-starter-kit-coin', data => {
				props.decreaseStartKitCoin(data.value);
			});
	
			socket.on('add-starter-kit-token', data => {
				props.increaseStartKitToken(data.value);
			});
	
			socket.on('remove-starter-kit-token', data => {
				props.decreaseStartKitToken(data.value);
			});
	
			socket.on('add-starter-kit-black-token', data => {
				props.increaseStartKitBlackToken(data.value);
			});
	
			socket.on('remove-starter-kit-black-token', data => {
				props.decreaseStartKitBlackToken(data.value);
			});
	
	
			socket.on('add-starter-kit-pin', data => {
				props.increaseStartKitPin(data.count);
			});
	
			socket.on('remove-starter-kit-pin', data => {
				props.decreaseStartKitPin(data.count);
			});
	
	
			socket.on('add-starter-kit-factory', data => {
				props.increaseStartKitFactory(data.count);
			});
	
			socket.on('remove-starter-kit-factory', data => {
				props.decreaseStartKitFactory(data.count);
			});
	
			socket.on('add-starter-kit-machine', data => {
				props.increaseStartKitMachine(data.count);
			});
	
			socket.on('remove-starter-kit-machine', data => {
				props.decreaseStartKitMachine(data.count);
			});
	
			socket.on('remove-starter-kit-production', data => {
				props.decreaseStartKitProductCard(data.value, data.color);
			})
	
			socket.on('update-starter-kit-production', data => {
				console.log("On Update Starter Kit Production");
				console.log(data);
	
				props.updateStartKitProduction(data.value)
			})
	
			socket.on('reset-form', data => {
				props.updateForm(data.type, data.value)
			})
	
			socket.on('update-form', data => {
				if (props.session.year === props.session.formSelectedYear) {
					if (data.option !== undefined) {
						props.updateFormOption(data.type, data.section, data.field, data.option, data.value)
					}else{
						props.updateFormOption(data.type, data.section, data.field, undefined, data.value)
					}
				}
			})
	
			socket.on('session-status-changed', data => {
				checkSessionStatus(data.status)
			})
		}
	}, [socket])

	const checkSessionStatus = (status) => {
		switch(parseInt(status)) {
			case 2: // Session Running
				setCanEdit(true)
				break;
			case 3: // Session Paused
				setCanEdit(false)
				break;
			default: 
				break;
		}
	}
	
	return (
		<DndProvider backend={Backend}>
			{
				(boardLayout) ? 
					<React.Fragment>
						<Header
							boardType={boardLayout}  //SimflexMAN
							canEdit={canEdit}
							isAdmin={isAdmin}
							logoutAction={props.logout}
							teamName={ teamName }
							languages={ languages }
						/>
						<KitsAndObjects 
							boardType={boardLayout}  //SimflexMAN
							canEdit={canEdit} 
						/>
						<div className={`main-app-bg ${boardLayout}`}> {/** SimflexMAN */}
							{
								(boardLayout === "SimflexMAN") ? 
									<SimFlexMAN canEdit={canEdit} />
									:
									<SimFlex canEdit={canEdit} />
							}
						</div>
						<FilesLibrary 
							boardType={boardLayout}  //SimflexMAN
							canEdit={canEdit} 
						/>
					</React.Fragment>
					:
					null
			}
		</DndProvider>
	)
}

const mapStateToProps = (state) => {
	return {
		session: state.session
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppProvider);